import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { apolloClient } from '@utils/wire';
import App from 'app';
import ReactDOM from 'react-dom';
import './global.scss';

const themeMui = createTheme({
  zIndex: {
    modal: 1250,
  },
});

const theme = extendTheme({});
ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <ThemeProvider theme={themeMui}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
