import React, { useState } from 'react';
import MyButton from '@components/button';
import { useGetTab } from '@utils/hooks/manageProduct/useGetTab';
import { useNavigate } from 'react-router-dom';
import AddMoreMediaModal from '../../../../../../modals/addMoreMediaModal';
import { useDeletedRealEstateProduct } from '@utils/hooks/manageProduct/useDeletedRealEstateProduct';
import ActionRealEstateProductModal from '../../../../../../modals/actionRealEstateProductModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import { useHiddenRealEstateProduct } from '@utils/hooks/manageProduct/useHiddenRealEstateProduct';
import MyModal from '@components/modal';
import RejectedRealEstateProductModal from '../../../../../../modals/rejectedRealEstateProductModal';
import { useProductContext } from '../../../product/context';
import { useRestoreDeletedProducts } from '@utils/hooks/manageProduct/useRestoreDeletedProducts';
import UpdateProductModal from '../../../../../../modals/updateProductStatusModal';
import MyDropdown from '@components/myDropdown';
import {
  realEstateForRentStatus,
  realEstateForSaleStatus,
} from '@utils/configs/remoteConfigs/realEstateCommonConfigs';
import ChangeStatusPanel from '@components/changeStatusPanel';
import { useGetLocation } from 'scenes/home/manageProduct/configs';
import { useApprovalRealEstateProduct } from '@utils/hooks/manageProduct/useApprovalRealEstateProduct';
import { useResubmitRealEstateProduct } from '@utils/hooks/manageProduct/useResubmitRealEstateProduct';
import RenewProductModal from 'modals/renewProductModal';
import { useDeleteListRealEstates } from '@utils/hooks/manageProduct/useDeleteListRealEstates';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

type Props = {
  data?: Object,
  realEstateId?: string,
  sameButton?: boolean,
  idSameProduct?: string,
  setCurrentTab?: Function,
};

export default function RightProductDetail({
  data,
  realEstateId,
  sameButton,
  idSameProduct,
  setCurrentTab,
}: Props) {
  const {
    product,
    myProduct,
    productPosted,
    productHidden,
    productDeleted,
    waitingApproval,
    rejectedDetails,
  } = useGetTab();
  const status = data?.approveStatus?.status;
  const {
    status: statusReject,
    setStatus,
    showChangeStatusModal,
    listSelected,
    setShowChangeStatusModal,
    stateFilter,
  } = useProductContext();
  const navigate = useNavigate();
  const { isTabProductPremium } = useGetLocation();
  const [showAddMoreMediaModal, setShowAddMoreMediaModal] = useState(false);
  const [showModalDeleted, setShowModalDeleted] = useState(false);
  const [showModalHidden, setShowModalHidden] = useState(false);
  const [showModalApproval, setShowModalApproval] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);
  const [showModalResubmit, setShowModalResubmit] = useState(false);
  const [showModalAppear, setShowModalAppear] = useState(false);
  const [showModalRestore, setShowModalRestore] = useState(false);
  const [showModalRenew, setShowModalRenew] = useState(false);
  const [showModalProductPosted, setShowModalProductPosted] = useState(false);
  const { permissions } = useGetMe();
  const { onSubmit: onSubmitPosted, loading: loadingPosted } =
    useDeleteListRealEstates(listSelected, setShowModalProductPosted);
  const { onSubmit, loading: loadingDeleted } = useDeletedRealEstateProduct(
    realEstateId,
    setShowModalDeleted
  );
  const {
    onHidden,
    onAppear,
    loading: loadingHidden,
  } = useHiddenRealEstateProduct(
    realEstateId,
    setShowModalHidden,
    setShowModalAppear
  );
  const { onRestore, loading: loadingRestoreProduct } =
    useRestoreDeletedProducts(realEstateId, setShowModalRestore);
  const { onApproval, loading: loadingApprovalProduct } =
    useApprovalRealEstateProduct(realEstateId, setShowModalApproval);
  const { onResubmit, loading: loadingResubmitProduct } =
    useResubmitRealEstateProduct(realEstateId, setShowModalResubmit);
  if (rejectedDetails) {
    return <></>;
  }
  const myId = localStorage.getItem('id');
  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="w-240px h-100vh ml-4 bg-white rounded-lg px-4 py-7 "
    >
      {sameButton ? (
        <div className="space-y-4">
          <MyButton
            onPress={() =>
              window.open(`/manageProduct/details/${idSameProduct}`)
            }
            type="gray"
            label="Xem sản phẩm ở tab mới"
          />
        </div>
      ) : (product || myProduct) && status !== 'rejected' ? (
        <div className="space-y-4">
          {/* {status !== 'pending' && (
            <div className="space-y-4">
              {permissions?.some(p => p.id === PermissionIds.PostProduct) && (
                <MyButton
                  onPress={() =>
                    navigate(`/publish/product?id=${realEstateId}`)
                  }
                  type="blue"
                  label="Đăng tin rao lên sàn"
                />
              )}
              {permissions?.some(
                p => p.id === PermissionIds.PostPremiumProduct
              ) && (
                <MyButton
                  onPress={() =>
                    navigate(`/publish/authorized?id=${realEstateId}`)
                  }
                  type="blue"
                  label="Đăng tin ký gửi lên sàn"
                />
              )}

              {permissions?.some(
                p => p.id === PermissionIds.PostAuthorizedProduct
              ) && (
                <MyButton
                  onPress={() =>
                    navigate(`/publish/premium?id=${realEstateId}`)
                  }
                  type="blue"
                  label="Đăng tin chính chủ lên sàn"
                />
              )}
            </div>
          )} */}
          {permissions?.some(p => p.id === PermissionIds.EditProduct) && (
            <MyButton
              onPress={() => {
                window.sessionStorage.setItem('idRE', realEstateId);
                window.sessionStorage.setItem(
                  'filter',
                  JSON.stringify(stateFilter)
                );
                navigate(
                  `/createB2BRealEstate/add-product-information?id=${realEstateId}&isEdit=true`
                );
              }}
              type="gray"
              label="Chỉnh sửa sản phẩm"
            />
          )}
          {permissions?.some(
            p => p.id === PermissionIds.AddProductAttachments
          ) && (
              <MyButton
                onPress={() => setShowAddMoreMediaModal(true)}
                type="gray"
                label="Bổ sung tài liệu sản phẩm"
              />
            )}
          {permissions?.some(p => p.id === PermissionIds.ViewProductNote) &&
            data?.approveStatus?.status !== 'pending' && (
              <MyButton
                onPress={() => setCurrentTab('comment')}
                type="gray"
                label="Ghi chú"
              />
            )}

          {permissions?.some(
            p => p.id === PermissionIds.ChangeProductState
          ) && (
              <MyDropdown
                popperPlacement="left"
                DropdownComponent={ChangeStatusPanel}
                dropdownContext={{
                  id: realEstateId,
                  status:
                    data?.typeOfDemand === 'sale'
                      ? realEstateForSaleStatus
                      : realEstateForRentStatus,
                  setShowChangeStatusModal,
                  currentStatus: data?.status,
                }}
              >
                <MyButton
                  id="change-status-btn"
                  type="gray"
                  label="Chuyển trạng thái sản phẩm"
                />
              </MyDropdown>
            )}
          <MyButton
            onPress={() =>
              window.open(`/manageProduct/details/${realEstateId}`)
            }
            type="gray"
            label="Xem sản phẩm ở tab mới"
          />
          <div className="space-x-2.5 flex flex-row">
            {permissions?.some(
              p => p.id === PermissionIds.ViewHiddenProductList
            ) && (
                <MyButton
                  onPress={() => {
                    setShowModalHidden(true);
                  }}
                  type="gray"
                  label="Ẩn"
                />
              )}
            {permissions?.some(p => p.id === PermissionIds.DeleteProduct) && (
              <MyButton
                onPress={() => {
                  setShowModalDeleted(true);
                }}
                type="gray"
                label="Xóa"
              />
            )}
          </div>
        </div>
      ) : (
        myProduct && (
          <div className="space-y-4">
            <MyButton
              onPress={() => {
                setShowModalResubmit(true);
              }}
              type="blue"
              label="Gửi duyệt lại"
            />
            <MyButton
              onPress={() => {
                navigate(
                  `/createB2BRealEstate/add-product-information?id=${realEstateId}&isEdit=true`
                );
              }}
              type="gray"
              label="Chỉnh sửa"
            />
          </div>
        )
      )}
      {productPosted && (
        <div className="space-y-4">
          {data?.creator?.id === myId && (
            <MyButton
              onPress={() => {
                isTabProductPremium
                  ? navigate(
                    `/publish?id=${realEstateId}&isEdit=true&isPremium=true`
                  )
                  : navigate(`/publish?id=${realEstateId}&isEdit=true`);
              }}
              type="gray"
              label="Chỉnh sửa tin rao"
            />
          )}
          {!isTabProductPremium && data?.creator?.id === myId && (
            <MyButton
              onPress={() => setShowModalRenew(true)}
              type="gray"
              label="Gia hạn"
            />
          )}
          <MyButton
            onPress={() => {
              window.open(
                `${process.env.REACT_APP_API_ENDPOINT_B2C}/chi-tiet-tin-rao/${realEstateId}`,
                '_blank'
              );
            }}
            type="gray"
            label="Chuyển tới tin trên web"
          />
          <MyDropdown
            popperPlacement="left"
            DropdownComponent={
              !data?.originalProductId ? NotFoundIdDropdown : FoundIdDropdown
            }
          >
            <MyButton
              id="view-original-btn"
              type="gray"
              label="Xem sản phẩm gốc"
              onPress={() =>
                data?.originalProductId
                  ? window.open(
                    `/manageProduct/details/${data?.originalProductId}`
                  )
                  : null
              }
            />
          </MyDropdown>
          {data?.creator?.id === myId ? (
            <MyButton
              onPress={() => setShowModalProductPosted(true)}
              type="gray"
              label="Xóa tin rao"
            />
          ) : null}
        </div>
      )}
      {waitingApproval && (
        <div className="space-y-4">
          {permissions?.some(p => p.id === PermissionIds.VerifyProduct) && (
            <MyButton
              onPress={() => {
                setStatus('approved');
                setShowModalApproval(true);
              }}
              type="blue"
              label="Duyệt"
            />
          )}
          {permissions?.some(p => p.id === PermissionIds.VerifyProduct) && (
            <MyButton
              onPress={() => {
                setStatus('rejected');
                setShowModalReject(true);
              }}
              type="gray"
              label="Từ chối"
            />
          )}
        </div>
      )}
      {productHidden && (
        <div className="space-x-2.5 flex flex-row">
          <MyButton
            onPress={() => {
              setShowModalAppear(true);
            }}
            type="gray"
            label="Bỏ ẩn"
          />
          {permissions?.some(p => p.id === PermissionIds.DeleteProduct) && (
            <MyButton
              onPress={() => {
                setShowModalDeleted(true);
              }}
              type="gray"
              label="Xóa"
            />
          )}
        </div>
      )}
      {productDeleted &&
        permissions?.some(p => p.id === PermissionIds.RestoreProduct) && (
          <div className="space-y-4">
            <MyButton
              onPress={() => {
                setShowModalRestore(true);
              }}
              type="gray"
              label="Khôi phục"
            />
          </div>
        )}
      <AddMoreMediaModal
        data={data}
        realEstateId={realEstateId}
        mediaUrls={data?.mediaUrls}
        open={showAddMoreMediaModal}
        onClose={() => setShowAddMoreMediaModal(false)}
      />
      <MyModalV2
        open={showModalDeleted}
        onClose={() => setShowModalDeleted(false)}
      >
        <ActionRealEstateProductModal
          loading={loadingDeleted}
          onConfirm={onSubmit}
          header={'Xóa sản phẩm'}
          question={`Bạn có chắc muốn xóa sản phẩm`}
          onClose={() => setShowModalDeleted(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showModalHidden}
        onClose={() => setShowModalHidden(false)}
      >
        <ActionRealEstateProductModal
          loading={loadingHidden}
          onConfirm={onHidden}
          header={'Ẩn sản phẩm'}
          question={`Bạn có chắc muốn ẩn sản phẩm`}
          onClose={() => setShowModalHidden(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showModalApproval}
        onClose={() => setShowModalApproval(false)}
      >
        <ActionRealEstateProductModal
          loading={loadingApprovalProduct}
          onConfirm={onApproval}
          header={'Duyệt sản phẩm'}
          question={`Bạn có chắc muốn duyệt sản phẩm`}
          onClose={() => setShowModalApproval(false)}
        />
      </MyModalV2>
      <MyModal
        maxWidth={515}
        isOpen={showModalReject}
        onClose={() => setShowModalReject(false)}
      >
        <RejectedRealEstateProductModal
          realEstateIds={realEstateId}
          status={statusReject}
          onClose={() => setShowModalReject(false)}
        />
      </MyModal>
      <MyModalV2
        open={showModalResubmit}
        onClose={() => setShowModalResubmit(false)}
      >
        <ActionRealEstateProductModal
          loading={loadingResubmitProduct}
          onConfirm={onResubmit}
          header={'Gửi duyệt lại'}
          question={`Bạn có chắc muốn gửi duyệt lại`}
          onClose={() => setShowModalResubmit(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showModalAppear}
        onClose={() => setShowModalAppear(false)}
      >
        <ActionRealEstateProductModal
          loading={loadingHidden}
          onConfirm={onAppear}
          header={'Bỏ ẩn sản phẩm'}
          question={`Bạn có chắc muốn bỏ ẩn sản phẩm`}
          onClose={() => setShowModalAppear(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showModalRestore}
        onClose={() => setShowModalRestore(false)}
      >
        <ActionRealEstateProductModal
          loading={loadingRestoreProduct}
          onConfirm={onRestore}
          header={'Khôi phục sản phẩm'}
          question={`Bạn có chắc muốn khôi phục sản phẩm`}
          onClose={() => setShowModalRestore(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showChangeStatusModal}
        onClose={() => setShowChangeStatusModal(false)}
      >
        <UpdateProductModal onClose={() => setShowChangeStatusModal(false)} />
      </MyModalV2>
      <MyModalV2 open={showModalRenew} onClose={() => setShowModalRenew(false)}>
        <RenewProductModal
          onClose={() => setShowModalRenew(false)}
          data={data}
        />
      </MyModalV2>
      <MyModalV2
        open={showModalProductPosted}
        onClose={() => setShowModalProductPosted(false)}
      >
        <ActionRealEstateProductModal
          loading={loadingPosted}
          onConfirm={onSubmitPosted}
          header={'Xóa sản phẩm'}
          question={`Bạn có chắc muốn xóa sản phẩm`}
          onClose={() => setShowModalProductPosted(false)}
        />
      </MyModalV2>
    </div>
  );
}

export function NotFoundIdDropdown() {
  return (
    <div className="w-216px h-108px bg-white rounded-lg shadow-md p-5 flex flex-col">
      <span className="font-medium text-16 text-blue-main flex">Thông báo</span>
      <span className="flex text-14 mt-4">Sản phẩm gốc đã bị xóa.</span>
    </div>
  );
}

export function FoundIdDropdown() {
  return <div></div>;
}
