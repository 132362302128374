import HeaderNavigation from '@components/header/components/headerNavigation';
import UserProfileDropdown from '@components/header/components/userProfileDropdown';
import { AdminRoutersConfigs } from '@components/horizontal-menu';
import HorizontalMenu from '@components/horizontal-menu/HorizontalMenu';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { filter, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AdminSpotlight from './components/admin-spotlight';
import CustomClickAwayListener from './components/customClickAwayListener';
import Spotlight from './components/spotlight';

Header.propTypes = {
  onClickIcon: PropTypes.func,
  onClickShow: PropTypes.func,
  onClose: PropTypes.func,
  onClickSpotlightIcon: PropTypes.func,
  onClickAdminSpotlightIcon: PropTypes.func,
  isShow: PropTypes.bool,
  openSpotlight: PropTypes.bool,
  openAdminSpotlight: PropTypes.boolean,
};

const BIHA_ID = process.env.REACT_APP_BIHA_ID;

export default function Header({
  onClickIcon,
  onClickShow,
  onClose,
  onClickSpotlightIcon,
  onClickAdminSpotlightIcon,
  isShow,
  openSpotlight,
  openAdminSpotlight,
}) {
  const navigate = useNavigate();
  const { permissions, company } = useGetMe();
  const configRoutes = AdminRoutersConfigs();
  const adminRouters = filter(configRoutes, router => router.isShow);
  const handleOnClickIcon = () => {
    onClickIcon?.();
  };
  const handleClickAdminSpotlightIcon = () => {
    onClickAdminSpotlightIcon?.();
  };
  const handleCustomClickAway = () => {
    onClose?.();
  };

  return (
    <div>
      <div
        style={{ zIndex: 1000 }}
        id="header"
        className="w-full h-60px bg-blue-main flex flex-row items-center px-4 py-3 justify-between fixed top-0"
      >
        <div className="flex flex-row items-center space-x-4 ">
          <img
            id="total-button"
            onClick={handleOnClickIcon}
            alt="total-icon"
            src="/icons/modules/total-icon.svg"
            className="bg-contain cursor-pointer pl-2"
          />
          <img
            onClick={() => navigate('/dashboard')}
            alt="logo"
            src="/svg/logo_header_Biha.svg"
            className="bg-contain cursor-pointer "
          />
          <HeaderNavigation />
        </div>
        <div className="flex flex-row items-center space-x-5 sm:space-x-3">
          {isEqual(company, BIHA_ID) && !isEmpty(adminRouters) && (
            <img
              alt="apps"
              src="/icons/apps.svg"
              className="bg-contain cursor-pointer"
              onClick={handleClickAdminSpotlightIcon}
            />
          )}
          {/* {permissions?.some(p => p.id === PermissionIds.PostProduct) && (
            <div className="flex flex-row border items-center border-white bg-blue-800 rounded-full px-2 py-1 cursor-pointer mr-4">
              <p
                onClick={() => navigate('/publish/product')}
                className="text-white font-medium mr-1 text-14 sm:text-13 pl-1"
              >
                Đăng tin rao
              </p>
              <img alt="plus-icon" src="/svg/plus-icon.svg" />
            </div>
          )} */}
          {/* <img
            alt="chat-ico"
            src="/svg/chat-icon.svg"
            className="cursor-pointer"
          />
          <img
            alt="noti-ico"
            src="/svg/noti-icon.svg"
            className="cursor-pointer"
          /> */}
          <UserProfileDropdown />
        </div>
      </div>
      <div
        style={{
          zIndex: 900,
          position: 'fixed',
          top: isShow ? 60 : -180,
          left: 0,
          width: '100%',
          transition: 'top 0.3s ease-out',
        }}
      >
        <CustomClickAwayListener onClickAway={handleCustomClickAway}>
          <HorizontalMenu
            onClickUrl={onClickShow}
            onClickSpotlightIcon={onClickSpotlightIcon}
            permissions={permissions}
          />
        </CustomClickAwayListener>
      </div>
      {openSpotlight ? (
        <Spotlight onClickSpotlightIcon={onClickSpotlightIcon} />
      ) : null}
      {openAdminSpotlight ? (
        <AdminSpotlight
          onClickAdminSpotlightIcon={handleClickAdminSpotlightIcon}
        />
      ) : null}
    </div>
  );
}
