import { lt, map } from 'lodash';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { RoutersConfigs } from '.';

HorizontalMenu.propTypes = {
  onClickUrl: PropTypes.func,
  onClickSpotlightIcon: PropTypes.func,
};

export default function HorizontalMenu({ onClickUrl, onClickSpotlightIcon }) {
  const handleExternalLinkClick = () => {
    onClickUrl?.(); 
  };
  const handleClickSpotlightIcon = () => {
    onClickSpotlightIcon?.();
  };
  const routers = RoutersConfigs();

  return (
    <div className="flex bg-white shadow-sm px-5 py-2 space-x-4 w-full">
      <button
        className="flex items-center justify-center px-2 pt-1 rounded-8px whiteButton hover:bg-blue-light"
        style={{ borderRadius: 8 }}
      >
        <div
          onClick={handleClickSpotlightIcon}
          className="flex flex-col justify-center items-center w-110px min-w-110px h-100px min-h-100px -mt-4"
        >
          <img
            className="w-42px h-42px"
            alt="image"
            src="/icons/modules/total.svg"
          />

          <span
            style={{
              color: '#22313e',
              fontSize: '12px',
              lineHeight: '15px',
            }}
            className=" font-semi pt-2"
          >
            Tất cả
          </span>
        </div>
      </button>
      <div className="flex space-x-4">
        {map(routers, router => {
          const isExternalLink = router?.path?.startsWith('https');
          let textWidth;
          const textToMeasure = router?.title;
          const hiddenText = document.getElementById('moduleName');
          if (hiddenText) {
            hiddenText.textContent = textToMeasure;
            textWidth = hiddenText.offsetWidth;
          }
          if (isExternalLink) {
            return (
              router.isShow && (
                <button
                  key={router?.id}
                  className={`flex items-center justify-center px-2 pt-1 rounded-8px whiteButton hover:bg-blue-light `}
                  style={{ borderRadius: 8 }}
                >
                  <a
                    href={router?.path}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleExternalLinkClick}
                    className={`flex flex-col justify-center items-center w-110px min-w-110px h-100px min-h-100px ${
                      lt(textWidth, 110) ? '-mt-4' : ''
                    }`}
                  >
                    <img
                      className="w-42px h-42px"
                      alt="image"
                      src={router?.icon}
                    />
                    <span
                      id="moduleName"
                      style={{
                        color: '#22313e',
                        fontSize: '12px',
                        lineHeight: '15px',
                      }}
                      className=" font-semi pt-2"
                    >
                      {router?.title}
                    </span>
                  </a>
                </button>
              )
            );
          }
          return (
            router.isShow && (
              <button
                key={router.id}
                className="flex items-center justify-center px-2 pt-1 rounded-8px whiteButton hover:bg-blue-light"
                style={{ borderRadius: 8 }}
              >
                <NavLink
                  to={router.path}
                  onClick={handleExternalLinkClick}
                  className={`flex flex-col justify-center items-center w-110px min-w-110px h-100px min-h-100px ${
                    lt(textWidth, 110) ? '-mt-4' : ''
                  }`}
                >
                  <img
                    className="w-42px h-42px"
                    alt="image"
                    src={router.icon}
                  />
                  <span
                    id="moduleName"
                    style={{
                      color: '#22313e',
                      fontSize: '12px',
                      lineHeight: '15px',
                    }}
                    className=" font-semi pt-2"
                  >
                    {router.title}
                  </span>
                </NavLink>
              </button>
            )
          );
        })}
      </div>
    </div>
  );
}
