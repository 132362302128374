import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { includes } from 'lodash';
export function RoutersConfigs() {
  const { permissions, modules } = useGetMe();
  return [
    {
      id: 1,
      title: 'Quản lý bộ máy tổ chức',
      path: process.env.REACT_APP_MANAGER_ORGANIZATION_URL,
      icon: '/icons/modules/manager-organization.svg',
      isShow: true,
    },
    {
      id: 2,
      title: 'Quản lý nhân viên',
      path: process.env.REACT_APP_MANAGEMENT_EMPLOYEE_URL,
      icon: '/icons/modules/manager-employee.svg',
      isShow: true,
    },
    {
      id: 3,
      title: 'Quản lý bất động sản',
      path: '/manageProduct/product',
      icon: '/icons/modules/manager-product.svg',
      isShow:
        includes(modules, 'REALESTATE') &&
        permissions?.some(p =>
          [
            PermissionIds.ManageProducts,
            PermissionIds.ViewProductList,
            PermissionIds.ViewProductGroup,
          ].includes(p.id)
        ),
    },
    {
      id: 4,
      title: 'Quản lý dự án',
      path: process.env.REACT_APP_MANAGEMENT_PROJECT_URL,
      icon: '/icons/modules/manager-project.svg',
      isShow: includes(modules, 'PROJECT'),
    },
    {
      id: 5,
      title: 'Quản lý CRM',
      path: process.env.REACT_APP_MANAGEMENT_CRM_URL,
      icon: '/icons/modules/manager-crm.svg',
      isShow: includes(modules, 'CRM'),
    },
    {
      id: 6,
      title: 'Quản lý khách hàng',
      path: process.env.REACT_APP_CUSTOMER_MANAGEMENT_URL,
      icon: '/icons/modules/manager-user.svg',
      // isShow: permissions?.some(p =>
      //   [
      //     PermissionIds.EditB2BCustomerInfo,
      //     PermissionIds.ListB2BCustomers,
      //     PermissionIds.LockUnlockB2BAccount,
      //     PermissionIds.ViewListB2BCustomers,
      //     PermissionIds.ViewB2CApprovedRealEstateList,
      //     PermissionIds.ViewB2CCompanyList,
      //     PermissionIds.ViewB2CRealEstateGroup,
      //     PermissionIds.ViewB2CRealEstateList,
      //     PermissionIds.ViewB2CRejectedRealEstateList,
      //     PermissionIds.B2CManagement,
      //   ].includes(p.id)
      // ),
      isShow: includes(modules, 'BUSINESS_ACCOUNT'),
    },
    {
      id: 7,
      title: 'Quản lý bán hàng & vùng',
      path: '/manageRealEstate/approved',
      icon: '/icons/modules/manager-sale-and-region.svg',
      // isShow: permissions?.some(p =>
      //   [
      //     PermissionIds.SaleAndRegionManagement,
      //     PermissionIds.ViewB2CRealEstateList,
      //     PermissionIds.ViewB2CApprovedRealEstateList,
      //     PermissionIds.ViewB2CRejectedRealEstateList,
      //   ].includes(p.id)
      // ),
      // isShow: includes(modules, 'SALE_MANAGEMENT'),
      isShow: false,
    },
    {
      id: '8',
      title: 'Tổng đài thông minh',
      path: process.env.REACT_APP_MANAGEMENT_CALLCENTER_URL,
      icon: '/icons/modules/call-center.svg',
      isShow: includes(modules, 'CALLCENTER'),
    },
    {
      id: '9',
      title: 'Quản lý nâng cấp tài khoản ATM Nhà',
      path: process.env.REACT_APP_MANAGEMENT_UPGRADE_ACCOUNT_URL,
      icon: '/icons/modules/management-upgrade-account.svg',
      isShow: includes(modules, 'UPGRADE_ACCOUNT'),
    },
  ];
}

export function AdminRoutersConfigs() {
  const { modules } = useGetMe();
  return [
    {
      title: 'Quản lý vay vốn ngân hàng',
      path: process.env.REACT_APP_LOAN_MANAGEMENT_URL,
      icon: '/icons/modules/manager-loan.svg',
      isShow: includes(modules, 'ADMIN_LOAN'),
    },
    {
      title: 'Quản lý hồ sơ công chứng',
      path: process.env.REACT_APP_NOTARIZATION_URL,
      icon: '/icons/modules/manager-notarization.svg',
      isShow: includes(modules, 'ADMIN_NOTARIZATION'),
    },
    {
      title: 'Quản lý đăng ký biến động',
      path: process.env.REACT_APP_REGISTER_URL,
      icon: '/icons/modules/manager-register.svg',
      isShow: includes(modules, 'ADMIN_REGISTER'),
    },
    {
      title: 'Quản lý tin đăng lên ATM Nhà',
      path: process.env.REACT_APP_B2B_PUBLIC_REALESTATE_URL,
      icon: '/icons/modules/manager-atmnha-published-post.svg',
      isShow: includes(modules, 'ADMIN_MANAGE_PUBLIC_REALESTATE'),
    },
    {
      title: 'Quản lý yêu cầu mua tài sản phát mãi',
      path: process.env.REACT_APP_FORECLOSURE_URL,
      icon: '/icons/modules/manager-foreclosure.svg',
      isShow: includes(modules, 'ADMIN_FORECLOSURE_REQUEST'),
    },
    {
      title: 'Quản lý khách hàng B2B',
      path: '/manageUser/b2b',
      icon: '/icons/modules/manager-customer.svg',
      isShow: includes(modules, 'ADMIN_BUSINESS_ACCOUNT'),
    },
  ];
}
