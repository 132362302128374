import { realEstate } from '@components/leftMenu/configs';
import MyDropdown from '@components/myDropdown';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { Link, useLocation, useNavigate } from 'react-router-dom';

type SelectionsProps = {
  context?: Object,
  close?: Function,
};

function Selections(props: SelectionsProps) {
  const {
    close,
    context: { configs },
  } = props;
  const navigate = useNavigate();
  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)', width: 300 }}
      className="w-full py-3 bg-white rounded-lg border border-gray-300"
    >
      {configs?.items.map((item, index) => {
        return (
          <div className={`${index > 0 ? 'mt-1' : undefined}`} key={index}>
            {item?.title && (
              <div
                style={{ color: '#80888F' }}
                className="text-14 font-medium mb-2 px-4"
              >
                {item?.title}
              </div>
            )}
            {item?.items?.map((itemSub, indexSub) => {
              return (
                <div
                  style={{ paddingLeft: item?.title ? 30 : 16 }}
                  key={indexSub}
                  onClick={() => {
                    close();
                    itemSub.pathname && navigate(itemSub.pathname);
                  }}
                  className={`selectItem ${indexSub > 0 ? 'mt-2' : 'mt-0'}`}
                >
                  {itemSub.title}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default function HeaderNavigation() {
  const style = { maxWidth: 180, textAlign: 'center' };
  const location = useLocation();
  const { permissions } = useGetMe();
  const manageRealEstate = {
    ...realEstate,
    items: realEstate?.items?.map(item => ({
      items: item.items.filter(
        p =>
          permissions?.map(per => per.id).includes(p.permissionId) ||
          !p.permissionId
      ),
    })),
  };
  return (
    <div className="flex flex-row items-center space-x-3 sm:space-x-2">
      {permissions?.some(p =>
        [
          PermissionIds.ManageProducts,
          PermissionIds.ViewProductList,
          PermissionIds.ViewProductGroup,
        ].includes(p.id)
      ) && (
        <MyDropdown
          DropdownComponent={Selections}
          dropdownContext={{ configs: manageRealEstate }}
        >
          <div
            style={style}
            className="flex flex-row items-center space-x-2 mr-6"
          >
            <div
              style={{
                color:
                  location.pathname.includes('manageProduct') ||
                  location.pathname.includes('createB2BRealEstate') ||
                  location.pathname.includes('publish')
                    ? '#FFE6B4'
                    : 'white',
              }}
              className={`text-13 font-medium cursor-pointer`}
            >
              {realEstate.title}
            </div>
            <img alt="arr" src="/svg/arrow-white.svg" />
          </div>
        </MyDropdown>
      )}
      {/* {permissions?.some(p =>
        [
          PermissionIds.EditB2BCustomerInfo,
          PermissionIds.ListB2BCustomers,
          PermissionIds.LockUnlockB2BAccount,
          PermissionIds.ViewListB2BCustomers,
          PermissionIds.ViewB2CApprovedRealEstateList,
          PermissionIds.ViewB2CCompanyList,
          PermissionIds.ViewB2CRealEstateGroup,
          PermissionIds.ViewB2CRealEstateList,
          PermissionIds.ViewB2CRejectedRealEstateList,
          PermissionIds.B2CManagement,
        ].includes(p.id)
      ) && (
        <MyDropdown
          DropdownComponent={Selections}
          dropdownContext={{ configs: realEstate }}
        >
          <Link to="manageProduct/my-product">
            <div
              style={style}
              className="flex flex-row items-center space-x-2 ml-4"
            >
              <div
                style={{
                  color: '#FFE6B4',
                }}
                className={`text-13 font-medium cursor-pointer`}
              >
                {realEstate.title}
              </div>
              <img alt="arr" src="/svg/arrow-white.svg" />
            </div>
          </Link>
        </MyDropdown>
      )} */}
      {/* {permissions?.some(p =>
        [
          PermissionIds.SaleAndRegionManagement,
          PermissionIds.ViewB2CRealEstateList,
          PermissionIds.ViewB2CApprovedRealEstateList,
          PermissionIds.ViewB2CRejectedRealEstateList,
        ].includes(p.id)
      ) && (
        <MyDropdown
          DropdownComponent={Selections}
          dropdownContext={{ configs: managePost }}
        >
          <div
            style={style}
            className="flex flex-row items-center space-x-2 ml-4"
          >
            <div
              style={{
                color: location.pathname.includes('manageRealEstate')
                  ? '#FFE6B4'
                  : 'white',
              }}
              className={`text-13 font-medium cursor-pointer`}
            >
              {managePost.title}
            </div>
            <img alt="arr" src="/svg/arrow-white.svg" />
          </div>
        </MyDropdown>
      )} */}
    </div>
  );
}
