import Header from '@components/header';
import { useState } from 'react';
import { View } from 'react-native-web';
import { Outlet } from 'react-router-dom';

export default function Home() {
  // eslint-disable-next-line no-undef
  const [open, setOpen] = useState(false);
  const [openSpotlight, setOpenSpotlight] = useState(false);
  const [openAdminSpotlight, setOpenAdminSpotlight] = useState(false);

  const handleClickShow = () => {
    setOpen(false);
  };
  const handleClickIcon = () => {
    setOpen(!open);
  };
  const handleClickSpotlightIcon = () => {
    setOpenSpotlight(!openSpotlight);
    setOpen(false);
  };
  const handleClickAdminSpotlightIcon = () => {
    setOpenAdminSpotlight(!openAdminSpotlight);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#f4f4f4',
      }}
    >
      <Header
        onClickIcon={handleClickIcon}
        onClickShow={handleClickShow}
        onClose={handleClose}
        onClickSpotlightIcon={handleClickSpotlightIcon}
        onClickAdminSpotlightIcon={handleClickAdminSpotlightIcon}
        isShow={open}
        openSpotlight={openSpotlight}
        openAdminSpotlight={openAdminSpotlight}
      />
      <Outlet />
    </View>
  );
}
