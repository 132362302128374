/* eslint-disable prettier/prettier */
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import Layout from 'layout';
import { includes, isEqual, map } from 'lodash';
import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Page403 from 'scenes/403/403';
import Page404 from 'scenes/404/404';
import { permissionsConfigs } from 'scenes/home/manageOrganization/permissionGroupDetail/perrmissionGroupDetailLeft/configs';
import ManageProduct from 'scenes/home/manageProduct';
import Product from 'scenes/home/manageProduct/product';
import ProductDetail from 'scenes/home/manageProduct/productDetail';
import ProductPosted from 'scenes/home/manageProduct/productPosted';
import PublishProduct from 'scenes/home/publishProduct';
import './global.scss';
import Authentication from './scenes/authentication';
import LoginForm from './scenes/authentication/login';
import Dashboard from './scenes/dashboard/dashboard';
import Home from './scenes/home';
import CreateB2BRealEstate from './scenes/home/createB2BRealEstate';
import AddProductInformation from './scenes/home/createB2BRealEstate/addProductInformation';
import CheckProduct from './scenes/home/createB2BRealEstate/checkProduct';

const ROUTER_BASENAME = process.env.REACT_APP_ROUTER_BASENAME;

const AccessDeniedPage = () => {
  const [open, setOpen] = useState(false);
  const [openSpotlight, setOpenSpotlight] = useState(false);
  return (
    <Page403
      open={open}
      setOpen={setOpen}
      openSpotlight={openSpotlight}
      setOpenSpotlight={setOpenSpotlight}
    />
  );
};

const App = () => {
  const { modules, permissions } = useGetMe();

  const routes = [
    {
      element: <Layout />,
      children: [
        {
          path: '/auth',
          element: <Authentication />,
          children: [
            {
              path: '/auth/login',
              element: <LoginForm />,
            },
          ],
        },
        {
          path: '/dashboard',
          element: <Navigate to='/manageProduct/product' />,
        },
        {
          path: '/',
          element: <Home />,
          children: [
            {
              path: '/createB2BRealEstate',
              element: <CreateB2BRealEstate />,
              children: [
                {
                  path: '/createB2BRealEstate/check-product',
                  element: <CheckProduct />,
                },
                {
                  path: '/createB2BRealEstate/add-product-information',
                  element: <AddProductInformation />,
                },
              ],
            },
            {
              path: '/manageProduct',
              element: <ManageProduct />,
              isShow: includes(modules, 'REALESTATE') &&
                permissions?.some(p =>
                  [
                    PermissionIds.ManageProducts,
                    PermissionIds.ViewProductList,
                    PermissionIds.ViewProductGroup,
                  ].includes(p.id)
                ),
              children: [
                {
                  path: 'product',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/my-product',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/product/waiting-for-approval',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/product/hidden',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/product/deleted',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/product/rejected',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/details/:id',
                  element: <ProductDetail />,
                },
                {
                  path: '/manageProduct/product-posted',
                  element: <ProductPosted />,
                },
                {
                  path: '/manageProduct/product/premium',
                  element: <ProductPosted />,
                },
                {
                  path: '/manageProduct/product/authorize',
                  element: <ProductPosted />,
                },
              ],
            },
            {
              path: '/publish',
              element: <PublishProduct />,
              children: [
                {
                  path: '/publish/product',
                  element: <PublishProduct />,
                },
                {
                  path: '/publish/authorized',
                  element: <PublishProduct />,
                },
                {
                  path: '/publish/premium',
                  element: <PublishProduct />,
                },
              ],
            },
            {
              path: '404',
              element: <Page404 />,
            },
          ],
        },
      ],
    },
  ];

  const renderRoutes = routeConfigs => {
    return routeConfigs.map(route => {
      return (
        <Route
          key={`route-${route.path}`}
          path={route.path}
          element={
            isEqual(route.isShow, false) ? <AccessDeniedPage /> : route.element
          }
          exact
        >
          {route.children && renderRoutes(route.children)}
        </Route>
      );
    });
  };
  return (
    <BrowserRouter basename={ROUTER_BASENAME}>
      <Routes>{renderRoutes(routes)}</Routes>
    </BrowserRouter>
  );
};

export default App;
